<template>
  <div class="row d-flex justify-content-center mb-5 px-4 px-lg-0">
    <div class="col-12 col-lg-6" style="height: 250px;">
      <router-link :to="{ name: 'article', params: { id: article.id } }">
        <img
          class="img-fluid card-blog"
          :src="article.articleImg"
          alt="article image"
        />
      </router-link>
    </div>
    <div
      class="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-start mt-3 mt-lg-0"
    >
      <router-link
        class="text-white text-decoration-none"
        :to="{ name: 'article', params: { id: article.id } }"
      >
        <span class="card-category"
          ><i class="fa-solid fa-right-from-bracket me-1"></i>
          {{ article.category + " > " + article.subcategory }}</span
        >
        <p class="p-0 m-0 card-title">
          {{ article.title }}
        </p>
        <span class="card-subtitle">{{ article.subtitle }}</span>
      </router-link>
      <div class="d-flex mt-4">
        <div class="card-date p-2">
          <i class="fa-solid fa-calendar me-1"></i>
          {{ formatedDate }}
        </div>
        <div class="card-lecture-time ms-4 p-2">
          <i class="fa-solid fa-stopwatch me-1"></i>
          {{ article.lectureTime + " min" }}
        </div>
      </div>
      <div class="mt-3">
        <img
          style="height: 35px"
          class="rounded-circle shadow-4-strong me-2 ms-1"
          alt="avatar2"
          src="assets/img/avatar.png"
        />
        <span style="color: #e1e5e7; font-size: 0.8rem; line-height: 0.8rem"
          >{{ 'by ' +  article.author }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Card",
  props: {
    article: Object,
  },
  setup(props) {
    var date = props.article.date.toDate();

    var options = { year: "numeric", month: "long", day: "numeric" };

    var formatedDate = date.toLocaleDateString("en-US", options);

    return {
      formatedDate,
    };
  },
};
</script>

<style lang="scss" scoped>
.card-title {
  font-size: 1.2rem;
  line-height: 1.7rem;
  font-weight: 500;
}

.card-subtitle {
  color: #9ba7b2;
  font-size: 0.9rem;
  line-height: 1.4rem;
  font-weight: 300;
}

.card-category {
  color: hsla(0, 0%, 100%, 0.87);
  font-size: 0.7rem;
  line-height: 0.7rem;
}

.card-blog {
  border: 0.0625rem solid #29343b;
  border-radius: 1.4rem;
  box-shadow: 8px 8px 1.25rem 0 #000;
  height: 100%;
  width: 100%;
}

.card-date {
  grid-row-gap: 0.625rem;
  border: 0.0625rem solid #29343b;
  border-radius: 0.5rem;
  background-color: transparent;
  color: #e1e5e7;
  font-size: 0.7rem;
  font-weight: 300;
}

.card-lecture-time {
  grid-row-gap: 0.625rem;
  border: 0.0625rem solid #29343b;
  border-radius: 0.5rem;
  background-color: transparent;
  color: #e1e5e7;
  font-size: 0.7rem;
  font-weight: 300;
}
</style>
