<template>
  <nav
    class="sticky-top navbar p-0 mb-3"
    :class="{ 'border-0': basicNav, 'pb-3': !basicNav }"
  >
    <div class="container-xl d-flex flex-column align-items-start">
      <div
        class="col-12 d-flex justify-content-center justify-content-lg-between"
      >
        <router-link
          class="navbar-brand text-decoration-none text-white fw-bold pt-4"
          to="/"
        >
          Valerio Pucciarelli
        </router-link>

        <button
          class="d-lg-none navbar-toggler position-absolute"
          style="top: 25px; right: 10px"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasNavbar"
          aria-controls="offcanvasNavbar"
        >
          <i class="fa-solid fa-bars text-white"></i>
        </button>

        <div class="d-none d-lg-flex align-items-center pt-3">
          <div class="me-3">
            <router-link
              to="/"
              class="nav-redirect-options text-white text-decoration-none mx-2"
              >Home</router-link
            >
            <a
              href="#newsletter"
              @click="focusNewsletters()"
              class="nav-redirect-options text-decoration-none mx-2"
              >Newsletter</a
            >
            <a
              href="#"
              class="nav-redirect-options text-decoration-none mx-2"
              data-bs-toggle="modal"
              data-bs-target="#adviceModal"
              >Consigli</a
            >
          </div>
          <div class="ps-3" style="border-left: 0.03125rem solid #29343b">
            <a
              href="https://valeriopucciarelli.it/"
              class="btn btn-primary border border-0 portfolio-redirect-button"
            >
              Portfolio
            </a>
          </div>
        </div>
      </div>
    </div>
    <div v-if="basicNav" class="progress w-100 rounded-0 mt-3">
      <div
        class="progress-bar"
        role="progressbar"
        :style="{ width: percent + '%' }"
        aria-label="Basic example"
        :aria-valuenow="percent"
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>
  </nav>
</template>

<script>
import { defineComponent, computed } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "navbar",
  props: {
    percent: Number,
  },
  setup() {

    const route = useRoute();

    const focusNewsletters = () => {
      const input = document.querySelector(".newsletter_input");
      window.setTimeout(() => input.focus(), 0);
    };

    var basicNav = computed(() => {
      if (route.path == "/") {
        return false;
      }

      return true;
    });

    return {
      basicNav,
      focusNewsletters,
    };
  },
});
</script>

<style lang="scss">
.border-0 {
  border-bottom: 0;
}

nav {
  border-bottom: 0.03125rem solid #29343b;
  background-color: #090f13;
}

.nav-category {
  height: 40px !important;
  margin-bottom: 0 !important;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
  border-radius: 6.25rem !important;
  background-color: #29343b !important;
  color: #e1e5e7 !important;
  font-size: 0.875rem !important;
  line-height: 0.875rem !important;
}

.nav-search {
  border: 0.0625rem solid #29343b !important;
  border-radius: 10px !important;
}

.nav-redirect-options {
  color: #9ba7b2 !important;
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
  font-weight: 400 !important;
  letter-spacing: 0.03125rem !important;
  text-decoration: none !important;
}

.portfolio-redirect-button {
  height: 40px !important;
  padding: 12px 25px !important;
  background-color: #5e6df6 !important;
  transition: 0.2s !important;
  color: #fff !important;
  font-size: 0.875rem !important;
  line-height: 0.875rem !important;
  letter-spacing: 2px !important;
  text-transform: uppercase !important;
}

textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
[type="text"].form-control:focus,
[type="password"].form-control:focus,
[type="email"].form-control:focus,
[type="tel"].form-control:focus,
[contenteditable].form-control:focus {
  box-shadow: inset 0 0 0;
}

.progress {
  background-color: transparent !important;
  height: 0.375rem !important;
}

.progress-bar {
  background-color: #5e6df6;
  height: 0.375rem !important;
}
</style>
