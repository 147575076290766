<template>
  <div class="containter-xl d-md-none">
    <div class="row m-0 p-0">
      <div class="accordion accordion-flush" id="accordionFlushExample">
        <a
          class="d-flex justify-content-between px-2 text-white text-decoration-none"
          href="#"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#flush-collapseOne"
          aria-expanded="false"
          aria-controls="flush-collapseOne"
        >
          <span>Indice</span>
          <i class="fa-solid fa-circle-plus ms-auto"></i>
        </a>

        <div
          id="flush-collapseOne"
          class="accordion-collapse collapse p-0"
          aria-labelledby="flush-headingOne"
          data-bs-parent="#accordionFlushExample"
        >
          <div class="accordion-body p-0 px-3">
            <ul class="list-group list-group-flush mt-3">
              <li
                v-for="(i, num) in index"
                :key="num"
                class="list-group-item list-group-item-sm p-0 py-2"
              >
                <a
                  :href="'#' + num"
                  class="breadcrumb-item breadcrumb-item-sm"
                  >{{ i }}</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-xl mt-5">
    <div class="row d-flex flex-column-reverse flex-md-row pb-5">
      <p class="d-none d-md-flex breadcrumb-link">
        Articoli / News / &nbsp;
        <span class="text-white">{{ article.title }}</span>
      </p>
      <div
        class="col-12 d-flex flex-column align-items-center align-items-md-start col-md-6 mt-5"
      >
        <h1 class="article-heading text-center text-md-start">
          {{ article.title }}
        </h1>
        <h3 class="article-subtitle">
          {{ article.subtitle }}
        </h3>
        <div class="d-flex align-items-center flex-column flex-lg-row mt-4">
          <div class="d-flex align-items-center">
            <div class="card-date">
              <i class="fa-solid fa-calendar me-1"></i>
              {{ formatedDate }}
            </div>
            <div class="card-lecture-time ms-4 p-2">
              <i class="fa-solid fa-stopwatch me-1"></i>
              {{ article.lectureTime + " min" }}
            </div>
          </div>
          <div class="d-none d-lg-block ms-lg-4 mt-lg-0">
            <img
              style="height: 35px"
              class="rounded-circle shadow-4-strong me-2"
              alt="avatar2"
              src="/assets/img/avatar.png"
            />
            <span
              style="color: #e1e5e7; font-size: 0.8rem; line-height: 0.8rem"
              >{{ "by " + article.author }}</span
            >
          </div>
          <div
            class="d-flex d-lg-none w-100 align-items-center ms-1 ms-lg-4 mt-3 mt-lg-0"
          >
            <img
              style="height: 35px"
              class="rounded-circle shadow-4-strong me-2"
              alt="avatar2"
              src="/assets/img/avatar.png"
            />
            <span
              style="color: #e1e5e7; font-size: 0.8rem; line-height: 0.8rem"
              >{{ "by " + article.author }}</span
            >
          </div>
        </div>
        <div class="d-none d-md-flex align-items-center mt-3">
          <a href="https://telegram.me/valerio_P03" target="_blank"
            ><i class="fa-brands fa-telegram fa-2x mx-2 text-white"></i
          ></a>
          <a href="https://github.com/Valerio-P" target="_blank"
            ><i class="fa-brands fa-github fa-2x mx-2 text-white"></i
          ></a>
          <a href="mailto:valerio.pucc@gmail.com"
            ><i class="fa-solid fa-envelope fa-2x mx-2 text-white"></i
          ></a>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <img
          class="img-fluid card-blog"
          :src="articleImg"
          alt="article image"
        />
      </div>
    </div>
  </div>
  <div id="article-content" class="container-fluid">
    <div class="container-xl">
      <div class="row py-5">
        <div
          v-html="article.content"
          ref="content"
          id="content"
          class="col-12 col-md-9 mt-3 px-2 px-md-5"
        ></div>
        <div class="d-none d-md-flex flex-md-column col-3 py-5">
          <span class="breadcrumb-title">Indice</span>
          <ul class="list-group list-group-flush mt-3">
            <li v-for="(i, num) in index" :key="num" class="list-group-item">
              <a :href="'#' + num" class="breadcrumb-item">{{ i }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref } from "vue";
import { useRouter, useRoute } from "vue-router";

import { db } from "@/core/services/FirebaseService";
import { storage } from "@/core/services/FirebaseService";
import { doc, getDoc } from "firebase/firestore";
import { ref as refStorage, getDownloadURL } from "firebase/storage";

export default defineComponent({
  name: "navbar",
  async setup() {
    const route = useRoute();
    const router = useRouter();

    let article = ref({});
    let articleImg = ref("");

    const id = route.params.id;

    const docRef = doc(db, "articles", id);
    const docSnap = await getDoc(docRef);

    var index = ref([]);

    var date;

    var getH2s = (content) => {
      var domParser = new DOMParser();
      var doc = domParser.parseFromString(content, "text/html");
      var h2s = doc.getElementsByTagName("h2");
      Array.from(h2s).forEach((h2, i) => {
        h2.setAttribute("id", i);
        index.value.push(h2.innerText);
      });
      var images = doc.getElementsByTagName("img");
      Array.from(images).forEach((image) => {
        image.classList.add("img-fluid");
      });
      return doc.body.innerHTML;
    };

    if (docSnap.exists()) {
      let finalArticle = docSnap.data();
      finalArticle.content = getH2s(finalArticle.content);
      article = finalArticle;
      date = finalArticle.date.toDate();
    } else {
      console.log("No such document!");
      router.push("/");
    }

    await getDownloadURL(refStorage(storage, `articles/wrapper/${id}`))
      .then((url) => {
        articleImg.value = url;
      })
      .catch(() => {
        articleImg.value = "/assets/img/error.jpg";
      });

    let options = { year: "numeric", month: "long", day: "numeric" };

    let formatedDate = date.toLocaleDateString("en-US", options);

    var content = ref("");

    return {
      article,
      articleImg,
      formatedDate,
      content,
      index,
    };
  },
});
</script>

<style lang="scss" scoped>
#article-content {
  background-color: #f0f2f3;
}

.article-heading {
  font-size: 2rem;
  line-height: 2.5rem;
  font-weight: 500;
  color: white;
}

.article-subtitle {
  color: #9ba7b2;
  font-size: 1.2rem;
  line-height: 1.7rem;
  font-weight: 300;
}

.breadcrumb-link {
  color: #9ba7b2;
  font-size: 0.7rem;
  line-height: 1.3rem;
  text-decoration: none;
}

.card-blog {
  border: 0.0625rem solid #29343b;
  border-radius: 1.4rem;
  box-shadow: 8px 8px 1.25rem 0 #000;
}

.card-date,
.card-lecture-time {
  padding: 0.7rem !important;
  grid-column-gap: 0.625rem !important;
  grid-row-gap: 0.625rem !important;
  border: 0.0625rem solid #29343b !important;
  border-radius: 0.5rem !important;
  background-color: transparent !important;
  color: #e1e5e7 !important;
  font-size: 0.8rem !important;
  line-height: 0.8rem !important;
  font-weight: 300 !important;
}

.list-group-item {
  background-color: #f0f2f3;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.breadcrumb-title {
  color: #090f13;
  font-size: 1rem;
  line-height: 1rem;
  font-weight: 400;
}

.breadcrumb-item {
  color: #29343b !important;
  text-decoration: none !important;
  font-size: 0.8rem !important;
  line-height: 1.3rem !important;
  font-weight: 400 !important;
}

.breadcrumb-item-sm {
  color: #9ba7b2 !important;
}

.list-group-item-sm {
  background-color: #090f13 !important;
  color: white !important;
  border-bottom: 0.5px solid #e1e5e7;
}
</style>
