// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCob6wWC0u0L0KG-bfbmMB_h95K52-Ffrg",
  authDomain: "my-blog-7238a.firebaseapp.com",
  projectId: "my-blog-7238a",
  storageBucket: "my-blog-7238a.appspot.com",
  messagingSenderId: "277656365492",
  appId: "1:277656365492:web:9d077ec4b7236ed8b96ae1",
  measurementId: "G-C7L6YC4BRG",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);

const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

export { db, auth, storage };
