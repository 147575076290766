<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="adviceModal"
    tabindex="-1"
    aria-labelledby="adviceModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div>
          <flow-form
            class="dark-mode"
            v-on:submit="onSubmit"
            v-bind:questions="questions"
            v-bind:language="language"
            v-bind:navigation="false"
            v-bind:progressbar="false"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Import necessary components and classes
import {
  FlowForm,
  QuestionModel,
  QuestionType,
  LanguageModel,
} from "@ditdot-dev/vue-flow-form";

import { auth, db } from "@/core/services/FirebaseService";

import {
  writeBatch,
  collection,
  doc,
  serverTimestamp,
} from "firebase/firestore";

export default {
  name: "example",
  components: {
    FlowForm,
  },
  data() {
    return {
      language: new LanguageModel({
        // Your language definitions here (optional).
        // You can leave out this prop if you want to use the default definitions.
      }),
      questions: [
        // QuestionModel array
        new QuestionModel({
          type: QuestionType.Email,
          title: "Inserisci la tua email",
          placeholder: "example@email.com",
          required: true,
        }),
        new QuestionModel({
          type: QuestionType.LongText,
          title: "Di cosa vorresti parlarmi?",
          placeholder: "...",
          required: true,
        }),
      ],
    };
  },
  methods: {
    async submitAdvice(data) {
      const currentUser = auth.currentUser;
      const email = data[0].answer;
      const content = data[1].answer;
      console.log(currentUser.uid)
      console.log(email)
      console.log(content)
      try {
        const batch = await writeBatch(db);

        const data = {
          email: email,
          content: content
        };

        const userRef = await doc(db, "users", currentUser.uid);
        const docRef = await doc(collection(db, "advices"));

        batch.set(
          userRef,
          { lastAdvice: serverTimestamp() },
          { merge: true }
        );
        batch.set(docRef, {
          data,
          userUID: currentUser.uid,
          createdAt: serverTimestamp(),
        });

        await batch.commit();
        console.log("Document written successfully!");
      } catch (e) {
        alert("Error, wait 1 minute before executing the request!");
        console.error("Error adding document: ", e);
      }
    },
    async onSubmit(questionList) {
      await this.submitAdvice(questionList);
    },
  },
};
</script>

<style>
@media (prefers-color-scheme: dark) {
  @import "~@ditdot-dev/vue-flow-form/dist/vue-flow-form.theme-minimal.css";
}
/* Import Vue Flow Form base CSS */
@import "~@ditdot-dev/vue-flow-form/dist/vue-flow-form.css";
/* Import one of the Vue Flow Form CSS themes (optional) */
/* @import '~@ditdot-dev/vue-flow-form/dist/vue-flow-form.theme-green.css'; */
/* @import '~@ditdot-dev/vue-flow-form/dist/vue-flow-form.theme-purple.css'; */
</style>
