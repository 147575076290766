<template>
  <footer id="footer" class="w-100">
    <div class="container-xl">
      <div class="main-footer row p-3 p-md-0 my-5">
        <div class="col-12 col-md-6 mb-3">
          <a
            class="navbar-brand text-decoration-none text-white fw-bold pt-4"
            href="#"
          >
            Valerio Pucciarelli
          </a>
          <p class="footer-text mt-3">
            Questo è un blog che esplora il mondo della tecnologia con
            approfondimenti, guide, news e recensioni.
          </p>
        </div>
        <div class="col-12 col-md-5 justify-content-md-center text-white">
          <a
            id="newsletter"
            class="navbar-brand text-decoration-none text-white fw-bold pt-4"
            href="#"
          >
            Iscriviti alla newsletter
          </a>
          <form @submit.prevent="sumbitNewsletter">
            <div
              class="input-group nav-search border-0 mt-3"
              style="max-width: 450px"
            >
              <input
                type="email"
                v-model="email"
                class="form-control newsletter_input"
                placeholder="example@email.com"
                aria-label="email"
                aria-describedby="basic-addon1"
                required
              />
              <button class="btn text-white newsletter_submit" type="submit">
                Iscriviti
              </button>
            </div>
          </form>
        </div>
      </div>
      <div
        class="d-flex flex-column flex-md-row align-items-center justify-content-md-between py-4 my-4"
      >
        <p class="text-white">
          {{
            "© " +
            new Date().getFullYear() +
            " Valerio Pucciarelli. All rights reserved."
          }}
        </p>
        <ul class="list-unstyled d-flex">
          <li class="ms-3">
            <a
              class="text-white text-decoration-none"
              href="https://valeriopucciarelli.it/imprint.html"
              >Imprint</a
            >
          </li>
          <li class="ms-3">
            <a
              class="text-white text-decoration-none"
              href="https://valeriopucciarelli.it/privacy.html"
              >Privacy</a
            >
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script>
import { ref } from "vue";
import { auth, db } from "@/core/services/FirebaseService";

import {
  writeBatch,
  collection,
  doc,
  serverTimestamp,
} from "firebase/firestore";

export default {
  name: "Footer",
  setup() {
    var email = ref("");
    var disabled = ref(false);

    async function sumbitNewsletter() {
      const currentUser = auth.currentUser;
      try {
        const batch = await writeBatch(db);

        const data = {
          email: email.value,
        };

        const userRef = await doc(db, "users", currentUser.uid);
        const docRef = await doc(collection(db, "newsletter"));

        batch.set(userRef, { lastNewsletter: serverTimestamp() }, { merge: true });
        batch.set(docRef, {
          data,
          userUID: currentUser.uid,
          createdAt: serverTimestamp(),
        });

        await batch.commit();
        console.log("Document written successfully!");
        email.value = "";
      } catch (e) {
        alert("Error, wait 1 minute before executing the request!");
        console.error("Error adding document: ", e);
      }
    }

    return {
      email,
      disabled,
      sumbitNewsletter,
    };
  },
};
</script>

<style lang="scss">
footer {
  border-top: 0.03125rem solid #29343b;
  background-color: #090f13;
}

.main-footer {
  border-bottom: 0.03125rem solid #29343b;
  background-color: #090f13;
}

h5 {
  color: white;
}

.footer-text {
  opacity: 1;
  color: #e1e5e7;
  line-height: 1.3rem;
}

.newsletter_input {
  color: white !important;
  border: 2px solid #29343b !important;
  border-right: 0 !important;
}

.newsletter_input:focus {
  border-color: #5e6df6 !important;
}

.newsletter_submit {
  border: 0.0625rem solid #5e6df6 !important;
  background-color: #5e6df6 !important;
}
</style>
