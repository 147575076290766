<template>
  <div
    class="offcanvas offcanvas-end bg-dark"
    style="width: 250px"
    tabindex="-1"
    id="offcanvasNavbar"
    aria-labelledby="offcanvasNavbarLabel"
  >
    <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="offcanvasNavbarLabel">
        Valerio Pucciarelli
      </h5>
      <a
        id="offcanvas_close"
        type="button"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      >
        <i class="fa-solid fa-x text-white"></i>
      </a>
    </div>
    <div class="offcanvas-body">
      <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
        <li class="nav-item">
          <a class="nav-link text-white" aria-current="page" href="#">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-white" @click="focusNewsletters()" href="#newsletter"
            >Newsletter</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link text-white"
            @click="dismis()"
            data-bs-toggle="modal"
            data-bs-target="#adviceModal"
            >Consigli</a
          >
        </li>
        <li class="nav-item">
          <div class="pt-3" style="border-top: 0.03125rem solid #29343b">
            <a
              href="https://valeriopucciarelli.it/"
              class="btn btn-primary border border-0 portfolio-redirect-button"
            >
              Portfolio
            </a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  setup() {
    const dismis = function () {
      document.querySelector("#offcanvas_close").click();
    };

    const focusNewsletters = () => {
      dismis();
      const input = document.querySelector(".newsletter_input");
      window.setTimeout(() => input.focus(), 0);
    };

    return {
      dismis,
      focusNewsletters
    };
  },
};
</script>
