<template>
  <Navbar :percent="percent" />
  <OffcanvasVue />
  <Suspense>
    <body id="body" class="container-fuild dark-theme">
      <router-view />
    </body>
  </Suspense>
  <Footer />

  <Advice />
  <CookiePolicyModal />
  <button
    id="cookie-policy-button"
    type="button"
    class="d-none"
    data-bs-toggle="modal"
    data-bs-target="#cookie-policy"
  ></button>
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";
import Cookies from "js-cookie";
import CookiePolicyModal from "./components/footer/CookiePolicyModal.vue";

import Navbar from "@/components/header/Navbar.vue";
import OffcanvasVue from "./components/header/Offcanvas.vue";
import Footer from "@/components/footer/Footer.vue";

import Advice from "@/components/modals/Advice.vue";

import { auth } from "@/core/services/FirebaseService";

import { onAuthStateChanged, signInAnonymously } from "firebase/auth";

export default defineComponent({
  name: "app",
  components: {
    Navbar,
    OffcanvasVue,
    Footer,
    Advice,
    CookiePolicyModal,
  },
  setup() {
    const firebaseAuthConfig = async () => {
      if (!auth.currentUser) {
        await signInAnonymously(auth);
      }

      await onAuthStateChanged(auth, async (user) => {
        if (!user) {
          await signInAnonymously(auth);
        }
      });
    };

    firebaseAuthConfig();

    var percent = ref(0);
    window.addEventListener(
      "scroll",
      function () {
        var h = document.documentElement,
          b = document.body,
          st = "scrollTop",
          sh = "scrollHeight";

        percent.value =
          ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 100;
      },
      true
    );

    onMounted(() => {
      if (
        Cookies.get("cookies-accepted") == undefined ||
        Cookies.get("cookies-accepted") == "false"
      ) {
        document.querySelector("#cookie-policy-button").click();
      }
    });

    return {
      percent,
    };
  },
});
</script>

<style lang="scss">
@import "@/scss/style.scss";
</style>
