<template>
  <Suspense>
    <template #default>
      <div v-if="logged" class="container-lg my-5 text-white">
        <div class="alert alert-success d-none" role="alert">
          A simple success alert—check it out!
        </div>
        <form @submit.prevent="submit" class="row g-3">
          <div class="col-md-4">
            <label for="validationServer01" class="form-label">Title</label>
            <input
              v-model="article.title"
              type="text"
              class="form-control"
              id="validationServer01"
              required
            />
            <div class="valid-feedback">Looks good!</div>
          </div>
          <div class="col-md-4">
            <label for="validationServer02" class="form-label">Subtitle</label>
            <input
              v-model="article.subtitle"
              type="text"
              class="form-control"
              id="validationServer02"
              required
            />
            <div class="valid-feedback">Looks good!</div>
          </div>
          <div class="col-md-4">
            <label for="validationServerUsername" class="form-label"
              >Author</label
            >
            <div class="input-group has-validation">
              <input
                v-model="article.author"
                type="text"
                class="form-control"
                id="validationServerUsername"
                aria-describedby="inputGroupPrepend3 validationServerUsernameFeedback"
                required
              />
              <div
                id="validationServerUsernameFeedback"
                class="invalid-feedback"
              >
                Please choose a author.
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <label for="validationServer04" class="form-label">Category</label>
            <select
              class="form-select text-white"
              id="validationServer04"
              aria-describedby="validationServer04Feedback"
              required
              v-model="article.category"
            >
              <option selected disabled>Choose...</option>
              <option>News</option>
            </select>
          </div>
          <div class="col-md-3">
            <label for="validationServer03" class="form-label"
              >Subcategory</label
            >
            <input
              v-model="article.subcategory"
              type="text"
              class="form-control"
              id="validationServer07"
              aria-describedby="validationServer03Feedback"
              required
            />
          </div>
          <div class="col-md-3">
            <label for="validationServer03" class="form-label"
              >Lecture Time</label
            >
            <input
              v-model="article.lectureTime"
              type="text"
              class="form-control"
              id="validationServer10"
              aria-describedby="validationServer10Feedback"
              required
            />
          </div>
          <div class="col-md-3">
            <label for="validationServer05" class="form-label">Image</label>
            <!--begin::Inputs-->
            <input
              type="file"
              @change="changeImage"
              name="avatar"
              accept=".png, .jpg, .jpeg"
            />
          </div>
          <div class="col-12">
            <div class="form-check form-switch">
              <input v-model="article.visible" class="form-check-input bg-primary" type="checkbox" role="switch" id="flexSwitchCheckDefault">
              <label class="form-check-label" for="flexSwitchCheckDefault">Visible</label>
            </div>
          </div>
          <QuillEditor ref="editor" toolbar="full" theme="snow" />
          <div class="w-100 d-flex justify-content-between mt-3">
            <button class="btn btn-danger" type="button" @click="logout()">
              Logout
            </button>
            <button type="submit" class="btn btn-success">Salva</button>
          </div>
        </form>
        <div class="list-group mt-5">
          <button
            v-for="article in articles"
            :key="article.id"
            type="button"
            class="list-group-item list-group-item-action bg-light"
            aria-current="true"
            @click="getArticle(article.id)"
          >
            {{ article.title }}
          </button>
        </div>
      </div>
      <div v-else class="container my-5 text-white">
        <form @submit.prevent="login">
          <h2 class="mb-3">Login</h2>
          <div class="input">
            <label for="email">Email address</label>
            <input
              class="form-control"
              type="text"
              name="email"
              autocomplete="username"
              v-model="email"
            />
          </div>
          <div class="input">
            <label for="password">Password</label>
            <input
              class="form-control"
              type="password"
              name="password"
              autocomplete="current-password"
              v-model="password"
            />
          </div>
          <button type="submit" class="mt-4 btn btn-primary" id="login_button">
            Login
          </button>
          <div
            class="alert alert-warning alert-dismissible fade show mt-5 d-none"
            role="alert"
            id="alert_1"
          >
            Lorem ipsum dolor sit amet consectetur, adipisicing elit.
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
            ></button>
          </div>
        </form>
      </div>
    </template>
    <template #fallback>
      <div>Loading Profile...</div>
    </template>
  </Suspense>
</template>

<script>
import {
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";

import { db, storage } from "@/core/services/FirebaseService";
import { collection, getDoc, getDocs, doc, setDoc } from "firebase/firestore";
import { ref as refStorage, uploadBytes } from "firebase/storage";

import { defineComponent, onMounted, ref } from "vue";
import { QuillEditor } from "@vueup/vue-quill";
import ImageUploader from "quill-image-uploader";
import axios from "axios";

import { v4 as uuidv4 } from "uuid";

export default defineComponent({
  components: {
    QuillEditor,
  },
  async setup() {
    var email = ref("");
    var password = ref("");
    var logged = ref(false);

    var auth = await getAuth();

    var editor = ref(null);

    var currentID = ref("");
    currentID.value = uuidv4().split("").slice(0, 8).join("");

    var articles = ref([]);
    var article = ref({
      title: "",
      subtitle: "",
      author: "",
      category: "",
      subcategory: "",
      lectureTime: "",
      visible: false,
    });

    const getArticles = async () => {
      const querySnapshot = await getDocs(collection(db, "articles"));
      articles.value = [];
      querySnapshot.forEach((doc) => {
        var article = doc.data();
        article.id = doc.id;
        articles.value.push(article);
      });
      console.log(articles.value);
    };

    const getArticle = async (id) => {
      currentID.value = id
      const docRef = doc(db, "articles", id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        article.value = docSnap.data();
        editor.value.setHTML(article.value.content)
      } else {
        console.log("No such document!");
      }
    };

    onAuthStateChanged(auth, (user) => {
      if (user) {
        logged.value = true;
        getArticles();
      } else {
        logged.value = false;
      }
    });

    const login = () => {
      signInWithEmailAndPassword(auth, email.value, password.value)
        .then((user) => {
          console.log(user);
          logged.value = true;
        })
        .catch((error) => {
          console.log(error.code);
        });
    };

    const logout = () => {
      signOut(auth)
        .then(() => {
          logged.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const submit = async () => {
      const content = editor.value.getHTML();

      await setDoc(doc(db, "articles", currentID.value), {
        content: content,
        title: article.value.title,
        subtitle: article.value.subtitle,
        author: article.value.author,
        category: article.value.category,
        subcategory: article.value.subcategory,
        lectureTime: article.value.lectureTime,
        visible: article.value.visible,
        date: new Date(),
      });
      getArticles()
    };

    const storageRef = refStorage(storage, "/articles/wrapper/" + currentID.value);

    const changeImage = async (event) => {
      const file = event.target.files[0];
      const metadata = {
        contentType: file.type,
      };
      // Upload the file and metadata
      await uploadBytes(storageRef, file, metadata);
    };

    const modules = {
      name: "imageUploader",
      module: ImageUploader,
      options: {
        upload: (file) => {
          return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append("image", file);

            axios
              .post("/upload-image", formData)
              .then((res) => {
                console.log(res);
                resolve(res.data.url);
              })
              .catch((err) => {
                reject("Upload failed");
                console.error("Error:", err);
              });
          });
        },
      },
    };
    return {
      logged,
      email,
      password,
      login,
      logout,
      editor,
      changeImage,
      submit,
      articles,
      getArticle,
      article,
      modules,
      onMounted,
    };
  },
});
</script>

<style scoped>
@import "@vueup/vue-quill/dist/vue-quill.snow.css";
.form-control {
  color: white !important;
}
</style>
