<template>
  <div
    class="modal fade"
    id="cookie-policy"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="cookie-policyLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog position-absolute bottom-0 end-0">
      <div class="modal-content">
        <div class="modal-body">
          <p style="color: #313131 !important; font-size: 14px !important">
            <span class="fw-bold">Informativa</span><br /><br />Noi e terze
            parti selezionate raccogliamo informazioni personali e utilizziamo
            cookie o tecnologie simili per finalità tecniche e, con il tuo
            consenso, anche per “miglioramento dell'esperienza”, “misurazione” e
            “targeting e pubblicità” come specificato nella cookie policy. Il
            rifiuto del consenso può rendere non disponibili le relative
            funzioni. Per quanto riguarda la pubblicità, noi e terze parti
            selezionate, potremmo utilizzare dati di geolocalizzazione precisi e
            l’identificazione attraverso la scansione del dispositivo, al fine
            di archiviare e/o accedere a informazioni su un dispositivo e
            trattare dati personali come i tuoi dati di utilizzo, per le
            seguenti finalità pubblicitarie: annunci e contenuti personalizzati,
            valutazione degli annunci e del contenuto, osservazioni del pubblico
            e sviluppo di prodotti. Puoi liberamente prestare, rifiutare o
            revocare il tuo consenso, in qualsiasi momento, accedendo al
            pannello delle preferenze. In caso di vendita di informazioni
            personali, puoi fare opt-out utilizzando il link "Non vendere le mie
            informazioni personali". Per saperne di più sulle categorie di
            informazioni personali raccolte e sulle finalità per le quali tali
            informazioni saranno utilizzate, si prega di fare riferimento alla
            nostra privacy policy. Usa il pulsante “Accetta” per acconsentire.
            Usa il pulsante “Rifiuta” o chiudi questa informativa per continuare
            senza accettare.
          </p>
          <div class="d-flex justify-content-between">
            <button
              @click="acceptCookiePolicy()"
              class="btn btn-primary"
              style="width: 40%"
              data-bs-dismiss="modal"
            >
              Accetta
            </button>
            <a
              href="https://valeriopucciarelli.it/"
              class="btn btn-primary"
              style="width: 40%"
              >Rifiuta</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import Cookies from "js-cookie";

export default defineComponent({
  name: "CookiePolocy",
  setup() {
    const acceptCookiePolicy = () => {
      Cookies.set("cookies-accepted", "true");
    };

    return {
      acceptCookiePolicy,
    };
  },
});
</script>
