<template>
  <div id="home" class="container-xl mt-5">
    <div class="row">
      <div class="col-12 d-flex justify-content-between">
        <p class="text-white mb-5">
          {{ articles.length + " articoli su " + articles.length }}
        </p>
        <button type="button" class="reset-button px-4 py-2">Shuffle</button>
      </div>
      <div class="col-12 col-xl-9">
        <Card
          v-for="article in articles"
          :key="article.id"
          :article="article"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import Card from "@/components/Card.vue";
import { db } from "@/core/services/FirebaseService";
import { collection, query, where, getDocs, orderBy } from "firebase/firestore";

import { storage } from "@/core/services/FirebaseService";
import { ref as refStorage, getDownloadURL } from "firebase/storage";

export default defineComponent({
  name: "HomeView",
  components: {
    Card,
  },
  setup() {
    var articles = ref([]);

    const getData = async () => {
      const querySnapshot = await getDocs(
        query(collection(db, "articles"), where("visible", "==", true), orderBy("date"))
      );
      articles.value = [];
      querySnapshot.forEach(async (doc) => {
        var article = doc.data();

        console.log(article);

        var articleImg = "";

        await getDownloadURL(refStorage(storage, `articles/wrapper/${doc.id}`))
          .then((url) => {
            articleImg = url;
          })
          .catch(() => {
            articleImg = "/assets/img/error.jpg";
          });
        article.id = doc.id;
        article.articleImg = articleImg;
        articles.value.push(article);
      });
    };

    getData();

    return {
      articles,
    };
  },
});
</script>

<style lang="scss">
.reset-button {
  height: 40px;
  background-color: transparent;
  border: 0.03125rem solid #29343b;
  border-radius: 40px;
  color: #9ba7b2;
  font-size: 0.8rem;
  line-height: 0.8rem;
  cursor: pointer;
}

.reset-button:hover {
  color: white;
}
</style>
