import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ArticleView from '../views/ArticleView.vue'
import BackOfficeView from '../views/BackOfficeView.vue'

const routes = [{
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/article/:id',
        name: 'article',
        component: ArticleView
    },
    {
        path: '/backoffice',
        name: 'backoffice',
        component: BackOfficeView
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router